<template>
  <main>
    <div class="position-relative">
      <!-- Hero -->
      <div class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/general-bg-2.svg') + ')'}">
        <div class="container space-2 space-top-3 text-center">
          <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-4">
            <h2 class="divider divider-text mb-3">Jan 2024 Release</h2>
            <h1 class="mb-3">What's New on FabriXAPI</h1>
            <p>Optimize your API management with enhanced monetization options and streamlined broadcast announcements</p>
          </div>
        </div>
      </div>
      <!-- End Hero -->

      <div class="container">
        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <h3 class="mb-3">Enhanced Portal Metrics on Dashboard</h3>
            <p>Unlock a clearer overview of your operations with refined metrics, including the number of APIs available, active subscriptions, pending approvals, and the number of developers who have visited your platform. These enhanced metrics provide valuable insights and help you make informed decisions to optimize your API management and drive success.</p>
          </div>

          <div class="col-12 col-md-6">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/img/products/whats-new-jan2024/portal-metrics-2.jpg" alt="Enhanced Portal Metrics on Dashboard">
            </div>
          </div>
        </div>

        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0 order-md-2">
            <h3 class="mb-3">New Monetization Option</h3>
            <p>You now have the option to bring your own Stripe account to enable monetization of your APIs on FabriXAPI. This feature empowers international users to effortlessly collect payments through their own Stripe accounts. Please note that a fee per transaction will apply. Start maximizing the potential of your APIs and explore the benefits of utilizing your own Stripe account on FabriXAPI.</p>
            <a href="https://guide.fabrixapi.com/en-us/bring-your-own-stripe/" class="font-weight-bold" target="_blank">Learn More About Bring Your Own Stripe <i class="fas fa-arrow-right ml-1" /></a>
          </div>
          <div class="col-12 col-md-6 order-md-1">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/img/products/whats-new-jan2024/monetization.jpg" alt="New Monetization Option">
            </div>
          </div>
        </div>

        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <h3 class="mb-3">Expanded Currency Options for API Plans</h3>
            <p>If you have chosen the Standard Flow payment collection approach, we have great news for you! We have expanded the range of currency options available for your API plans. This improvement offers greater flexibility in choosing currencies that align with the specific requirements of diverse international users. Enjoy the benefits of our enhanced currency options and create a more tailored experience for your users.</p>
            <a href="https://guide.fabrixapi.com/en-us/standard-flow/" class="font-weight-bold" target="_blank">Learn More <i class="fas fa-arrow-right ml-1" /></a>
          </div>
          <div class="col-12 col-md-6">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/img/products/whats-new-jan2024/currency.jpg" alt="Expanded Currency Options for API Plans">
            </div>
          </div>
        </div>

        <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0 order-md-2">
            <h3 class="mb-3">Engage API Subscribers with Broadcast Announcements</h3>
            <p>As an API Provider, you now have the ability to send announcements to either all of your API subscribers or to specific groups of subscribers. This new feature streamlines the process of making broadcast announcements, allowing you to efficiently communicate important updates to your subscribers. Stay connected and keep your subscribers informed with ease.</p>
            <a href="https://guide.fabrixapi.com/en-us/send-announcements/" class="font-weight-bold" target="_blank">Learn More <i class="fas fa-arrow-right ml-1" /></a>
          </div>
          <div class="col-12 col-md-6 order-md-1">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/img/products/whats-new-jan2024/announcements.jpg" alt="Engage API Subscribers with Broadcast Announcements">
            </div>
          </div>
        </div>

        <!-- <div class="row align-items-center space-2">
          <div class="col-12 col-md-6 mb-4 mb-md-0">
            <h3 class="mb-3">Online or Offline Payment—it's up to You</h3>
            <p>To enhance monetization flexibility, we now offer more collection options and empowering you to collect payments through your preferred offline methods, such as offline bank transfers or separate payment links. This enables you to accommodate various payment preferences and provides a seamless experience for your API users.</p>
            <a href="https://guide.fabrixapi.com/en-us/set-up-api-monetization/" class="font-weight-bold" target="_blank">Learn More <i class="fas fa-arrow-right ml-1" /></a>
          </div>
          <div class="col-12 col-md-6">
            <div class="w-80 w-md-100 w-lg-75 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-3.svg" alt="Manage API Subscriptions">
            </div>
          </div>
        </div> -->
      </div>

      <!-- Book a Demo -->
      <div class="container space-top-2 space-bottom-3">
        <div class="card border-0 shadow" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-hero-1.svg`)})`}">
          <div class="card-body text-center py-5">
            <h2>Ready to Build Your Own API Store?</h2>
            <p>Start building for free and share your APIs to the world.</p>
            <a href="https://provider-portal.fabrixapi.com/" class="btn btn-primary mt-3" target="_blank">Start for Free <i class="far fa-arrow-right ml-1" /></a>
          </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
import 'bootstrap'
export default {
  name: 'WhatsNewJan2024',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
    this.loadScriptUpgradeForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "b20fb3bd-0aea-45b5-9493-c02301f45050"
        });
      `
      this.$refs.upgradeForm.appendChild(secondScript)
    })
  },
  methods: {
    loadScriptUpgradeForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.upgradeForm.appendChild(script)
    }
  },
  metaInfo() {
    return {
      title: 'Whats New on FabriXAPI - Jan 2024',
      titleTemplate: '%s | FabriXAPI',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/whats-new-jan2024' }
      ],
      meta: [
        { name: 'description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'What\'s New on FabriXAPI - Jan 2023 | FabriXAPI' },
        { property: 'og:description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/whats-new-jan2024' }
      ]
    }
  }
}
</script>

<style lang="scss">
</style>
